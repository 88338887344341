<template>
  <div class="cover-box">
    <i class="waiting-icon"></i>
    <span class="waiting-text">......</span>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {REQUEST_ORDER_INFO} from '@/store/mutation-types'
  let checkTimer;
  export default {
    name: 'Waiting',
    data: function () {
      return {
        equipmentId: localStorage.getItem('equipment_id'),
        checkCount: 0
      }
    },
    computed: {
      ...mapState({
        orderStatus: state => state.SaleOrder.status,
        orderCoupon: state => state.SaleOrder.coupon_code
      })
    },
    async created() {
      this.checkCount = await this.$getProfileValue('PHONE-CHECKING_PAY_TIMEOUT');
      clearInterval(checkTimer);
      checkTimer = setInterval(async () => {
        if (this.checkCount > 0) {
          this.outsideCount = this.outsideCount - 1;
          await this.$store.dispatch(REQUEST_ORDER_INFO, {quotationCode: localStorage.getItem('quotation_code')});
          if (this.orderStatus === 'PAID') {
            clearInterval(checkTimer);
            this.$router.push('/pay-success')
          }
        }
        else {
          clearInterval(checkTimer);
          this.$router.push('/pay-failure');
        }
      }, 3000)
    },
    beforeDestroy() {
        //清除定时器
        clearInterval(checkTimer);
        // console.log("Before Destroy");
    },
    destroyed() {
        //清除定时器
        // console.log("Destroyed");
    },
  }
</script>

<style lang='scss' scoped>
  .cover-box {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    overflow: hidden;
    text-align: center;
    .waiting-icon{
      display: inline-block;
      margin-top: 50px;
      height: 130px;
      width: 130px;
      background-image: url(~@/assets/mobile/loading.gif);
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .waiting-text{
      display: block;
      line-height: 2;
    }
  }
</style>
